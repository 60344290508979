
<!-- atendance -->
<template>
  <div class="section-nopad">
    <Section :title="title1" :hasFooter="false">
      <ul class="cardcontent-info">
        <li v-for="(item, index) in data1" :key="index">
          <div class="lable">{{ item.label }}</div>
          <i class="iconfont iconedit" @click="handleEdit1(item)"></i>
        </li>
      </ul>
    </Section>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      title1: "Health check",
      data1: [
        { label: "Illness", value: "illness" },
        { label: "Visual check", value: "visualCheck" },
      ],
    };
  },
  methods: {
    handleEdit1(item) {
      this.$router.push({
        name: "tablePage",
        query: {
          title: item.label,
          moduleType: item.value,
          moduleName: "healthCheck",
          activeIndex: "settingAttendance",
        },
      });
    },
  },
  created() {},
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
</style>